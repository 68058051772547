import React, { FunctionComponent, ReactNode } from 'react'
import { Header, MatillionLogo } from '@matillion/component-library'

import HubHomeLink from './components/HubHomeLink'
import HubUserMenu from './HubUserMenu'
import HubLogoutButton from './HubLogout'
import HubPlatformMenu from './HubPlatformMenu'

export interface HubHeaderProps {
  /**
   * An optional logo, which overrides the HubHeader's default
   * if provided. It's recommended that the default logo is used,
   * as it links back to the Matillion Start application, which
   * is considered the user's "home".
   */
  logo?: ReactNode
  /**
   * An optional platformMenuHidden setting, which is set to false by default.
   * If set to true, will hide the platform menu.
   */
  platformMenuHidden?: boolean
  /**
   * An optional userMenuHidden setting, which is set to false by default.
   * If set to true, will hide the user menu and show a logout link.
   */
  userMenuHidden?: boolean
  /**
   * An optional disableHubLink setting, which is set to false by default.
   * If set to true, will show the Matillion logo without being a link to the hub.
   */
  disableHubLink?: boolean
}

/**
 * The HubHeader renders the entire Matillion application header. This
 * includes the Matillion logo/home link, the User Menu, and the
 * Platform Navigation menu.
 *
 * The FlagProvider must be used beneath the [[AuthProvider]].
 *
 * @param props See [[HubHeaderProps]].
 * @category Components
 */
const HubHeader: FunctionComponent<HubHeaderProps> = ({
  logo,
  platformMenuHidden = false,
  userMenuHidden = false,
  disableHubLink = false
}) => (
  <Header
    platformMenu={!platformMenuHidden && <HubPlatformMenu />}
    menu={userMenuHidden ? <HubLogoutButton /> : <HubUserMenu />}
    logo={
      logo ??
      (disableHubLink ? (
        <MatillionLogo />
      ) : (
        <HubHomeLink>
          <MatillionLogo />
        </HubHomeLink>
      ))
    }
  />
)

export default HubHeader
