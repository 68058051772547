import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import config from './config'
import AgentManagerApp from './AgentManagerApp'
import {
  AuthProvider,
  RegionGuard,
  ServiceRegistryProvider
} from '@matillion/hub-client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from './api/queryClient'
import './styles/base.scss'
import { BrowserRouter } from 'react-router-dom'
import AgentFlagProvider from 'flags/FlagProvider'

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const rootElement = document.getElementById('root') as HTMLElement
rootElement.setAttribute('data-app-environment', config.environment)
rootElement.setAttribute('data-app-version', config.version)

const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <AuthProvider environment={config.authEnvironment}>
      <ServiceRegistryProvider>
        <BrowserRouter>
          <AgentFlagProvider>
            <QueryClientProvider client={queryClient}>
              <RegionGuard strictAccountRegion>
                <AgentManagerApp />
                <ReactQueryDevtools position="bottom-right" />
              </RegionGuard>
            </QueryClientProvider>
          </AgentFlagProvider>
        </BrowserRouter>
      </ServiceRegistryProvider>
    </AuthProvider>
  </StrictMode>
)
