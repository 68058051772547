import { FlagProvider } from '@matillion/hub-client'
import config from 'config'
import type { ReactElement } from 'react'

import flagDefaults from './defaults.json'

const shouldConnectToLD =
  // @ts-expect-error window.Cypress does not exist
  !window.Cypress &&
  (process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_LAUNCH_DARKLY === 'true')

interface AgentFlagProviderProps {
  children: ReactElement
}

const AgentFlagProvider = ({ children }: AgentFlagProviderProps) => {
  return (
    <FlagProvider
      flags={flagDefaults}
      launchDarklyClientId={config.launchDarkly.clientId}
      waitForFlags
      offline={!shouldConnectToLD}
    >
      {children}
    </FlagProvider>
  )
}

export default AgentFlagProvider
