import type { FC } from 'react'

import { useEffect } from 'react'
import { Loader } from '@matillion/component-library'
import { useAuth } from '@matillion/hub-client'

const Login: FC = () => {
  const { login } = useAuth()

  useEffect(() => {
    login({
      /*
       * billing-login is the default Matillion Hub login screen,
       * but this can be changed on a per-app basis
       */
      pageBranding: 'billing-login'
    })
  }, [login])

  return <Loader />
}

export default Login
