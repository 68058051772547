import { FunctionComponent } from 'react'
import { PlatformMenuServiceItem } from '@matillion/component-library'

import useMatillionApplicationURL from '../../effects/useMatillionApplicationURL'
import { useServiceUrl } from '../../effects/useServiceUrl'

interface HubPlatformMenuServiceItemProps {
  name: string
  description: string
  serviceKey: string
}

const HubPlatformMenuServiceItem: FunctionComponent<
  HubPlatformMenuServiceItemProps
> = ({ name, description, serviceKey }) => {
  const url = useServiceUrl(serviceKey, true)
  const { isActiveApplication } = useMatillionApplicationURL(url)

  return (
    <PlatformMenuServiceItem
      as="a"
      active={isActiveApplication}
      href={url.toString()}
      name={name}
      description={description}
    />
  )
}

export default HubPlatformMenuServiceItem
