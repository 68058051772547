import type { FC, ReactNode } from 'react'
import { Layout, Toaster } from '@matillion/component-library'
import { ModalProvider } from '../Modals/ModalContext'
import { HubHeader } from '@matillion/hub-client'

const AppLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <Layout header={<HubHeader />}>
    <Toaster.Provider theme="dark" align="right" position="top" withFade={7}>
      <ModalProvider>{children}</ModalProvider>
    </Toaster.Provider>
  </Layout>
)

export default AppLayout
