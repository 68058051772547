/* eslint-disable jsx-a11y/aria-role */
import { Suspense, lazy, memo } from 'react'
import type { FC } from 'react'
import { Loader } from '@matillion/component-library'
import { Navigate, useRoutes } from 'react-router-dom'
import AppLayout from './components/AppLayout'
import { useAuth, RoleGuard } from '@matillion/hub-client'
import Login from './modules/Auth/components/Login'

const AgentsPage = lazy(
  async () => import('modules/Agent/components/agentsPage')
)

const CreateAgentPage = lazy(
  async () => import('modules/Agent/components/createAgentPage')
)

const AgentDetailsPage = lazy(
  async () => import('modules/Agent/components/agentDetailsPage')
)

const StartAgentPage = lazy(
  async () => import('modules/Agent/components/startAgentPage')
)

const Logout = lazy(async () => import('modules/Auth/components/Logout'))

export enum APP_ROUTES {
  ROOT = '/',
  AGENTS = '/agents',
  AGENT_DETAILS = '/agents/:agentId',
  START = '/start',
  CREATE_AGENT = '/create-agent',
  LOG_OUT = '/logout'
}

const routes = [
  {
    path: APP_ROUTES.ROOT,
    element: <Navigate to={APP_ROUTES.AGENTS} replace />
  },
  { path: APP_ROUTES.AGENTS, element: <AgentsPage /> },
  { path: APP_ROUTES.AGENT_DETAILS, element: <AgentDetailsPage /> },
  { path: APP_ROUTES.START, element: <StartAgentPage /> },
  { path: APP_ROUTES.CREATE_AGENT, element: <CreateAgentPage /> },
  { path: APP_ROUTES.LOG_OUT, element: <Logout /> }
]

const AgentManagerApp: FC = () => {
  const { isLoggedIn } = useAuth()
  const appRoutes = useRoutes(routes)

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <RoleGuard role="agentmanager:user">
      <AppLayout>
        <Suspense fallback={<Loader />}>{appRoutes}</Suspense>
      </AppLayout>
    </RoleGuard>
  )
}

export default memo(AgentManagerApp)
