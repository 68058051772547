import type { FC, ReactNode } from 'react'

import { useMemo, useState, useContext, createContext } from 'react'

type RenderMethod = ({ onClose }: { onClose: () => void }) => ReactNode

interface ModalContextValue {
  showModal: (render: RenderMethod) => void
}

interface ModalOperations {
  render: RenderMethod
  onClose: () => void
}

const ModalContext = createContext<ModalContextValue | null>(null)

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [modalOperationsStack, setModalOperationsStack] = useState<
    ModalOperations[]
  >([])

  const providerValue = useMemo(
    () => ({
      showModal: (renderFn: RenderMethod) => {
        const onClose = () => {
          setModalOperationsStack((current) =>
            current.filter(({ render }) => render !== renderFn)
          )
        }

        setModalOperationsStack((current) => [
          ...current,
          { render: renderFn, onClose }
        ])
      }
    }),
    []
  )

  const currentModalOperation =
    modalOperationsStack[modalOperationsStack.length - 1]

  return (
    <ModalContext.Provider value={providerValue}>
      {children}

      {!!currentModalOperation &&
        currentModalOperation.render({
          onClose: currentModalOperation.onClose
        })}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => {
  const modalContext = useContext(ModalContext)

  if (!modalContext) {
    throw new Error('No ModalProvider found')
  }

  return modalContext
}
