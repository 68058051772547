import {
  HeaderMenuItemGroup,
  HeaderMenuItem,
  UserMenu
} from '@matillion/component-library'
import { FunctionComponent, useCallback } from 'react'

import useAuth from '../../effects/useAuth'
import useUser from '../../effects/useUser'

import MyDetailsLink from './components/MyDetailsLink'
import SwitchOrganisationLink from './components/SwitchOrganisationLink'

const HubUserMenu: FunctionComponent = () => {
  const { profile } = useUser()
  const { logout } = useAuth()

  const onLogout = useCallback(() => {
    logout({ returnTo: window.location.origin })
  }, [])

  return (
    <UserMenu profile={profile} data-testid="hub-user-menu">
      <HeaderMenuItemGroup small>
        <HeaderMenuItem as={MyDetailsLink}>My details</HeaderMenuItem>

        <HeaderMenuItem as={SwitchOrganisationLink}>
          Switch account
        </HeaderMenuItem>
      </HeaderMenuItemGroup>

      <HeaderMenuItemGroup small>
        <HeaderMenuItem as="button" onClick={onLogout}>
          Logout
        </HeaderMenuItem>
      </HeaderMenuItemGroup>
    </UserMenu>
  )
}

export default HubUserMenu
