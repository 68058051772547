import type { AuthProviderEnvironment } from '@matillion/hub-client'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'

export const getEnvFromUrl = (url: string) => {
  const currentEnv = new URL(url).hostname.split('.')[1].split('-')[1]

  if (['local', 'perf', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}

const environment =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? process.env.REACT_APP_ENVIRONMENT
    : getEnvFromUrl(window.location.href)

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  AGENT_MANAGER_API: string
  AGENT_MANAGER_API_AUDIENCE: string
  DATADOG?: Record<string, string>
  LAUNCH_DARKLY: Record<string, string>
  HUB_API: string
  GTM_ID?: string
  AGENT_CONFIGURATION_TEMPLATES: Record<string, string>
  MATILLION_ENV?: string
} & Record<string, string | undefined>

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

const config = {
  environment,
  version,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  hubApi: envConfig.HUB_API,
  agentManagerApi: envConfig.AGENT_MANAGER_API,
  agentManagerApiAudience: envConfig.AGENT_MANAGER_API_AUDIENCE,

  agentConfigurationTemplates: {
    cloudFormationTemplateUrl:
      envConfig.AGENT_CONFIGURATION_TEMPLATES.CLOUDFORMATION_TEMPLATE_URL,
    matillionEnv: envConfig.MATILLION_ENV
  },

  gtmId: envConfig.GTM_ID,
  launchDarkly: {
    clientId: envConfig.LAUNCH_DARKLY.CLIENT_ID
  },

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    applicationId: '44272d01-659e-4675-9759-89a3049dc885',
    clientToken: 'pub4b58e339c50aec09d5ea6df63cc544ef',
    site: 'datadoghq.eu',
    service: 'agent-manager-frontend',
    rumService: 'agent-manager-frontend',
    sampleRate: 100
  }
}

export default config
