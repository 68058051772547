import { FunctionComponent, ReactElement, useMemo } from 'react'
import {
  LDProvider,
  ProviderConfig,
  camelCaseKeys
} from 'launchdarkly-react-client-sdk'

import { FlagContext } from '../context'
import getConfig from '../config'

import useAuth from '../effects/useAuth'
import useUser from '../effects/useUser'
import useEnvironment from '../effects/useEnvironment'

import AsyncFlagProvider from './AsyncFlagProvider'

export interface FlagProviderProps {
  /**
   * The client ID for your application, as listed in the LaunchDarkly
   * admin panel. If left blank, the default Matillion application
   * will be used, but we recommend that you create your own.
   */
  launchDarklyClientId?: string

  /**
   * Default values for your feature flags, which will be used in your
   * application until LaunchDarkly has finished loading. When this happens,
   * the default values set in the admin panel will overwrite these defaults.
   *
   * If the `AuthProvider` is running in `AuthProviderEnvironment.test` mode,
   * LaunchDarkly will not be loaded, meaning that these default flags will be
   * used at all times.
   */
  flags: ProviderConfig['flags']

  /**
   * If set to true, any children of this component will not be rendered
   * until flag values have been loaded for the current viewer from
   * LaunchDarkly. Until then, a Loader will be displayed.
   */
  waitForFlags?: boolean

  /**
   * If set to true, the FlagProvider will launch in offline mode. This
   * will prevent it from connecting to LaunchDarkly, which means it will
   * only ever provide the default feature flags to any components calling
   * `useFlags`.
   *
   * By default, this will mirror the value of `offline` in the AuthProvider.
   */
  offline?: boolean

  /**
   * If set to true, LaunchDarkly will convert your feature flag keys into camelCase,
   * for easier use within javascript syntax. (eg. flags.myFlagName vs flags['my-flag-name']).
   * This behaviour is enabled by default, but may need to be turned off for applications where
   * the original kebab-case flag names are needed.
   *
   * See https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys-in-the-react-sdk for more information
   */
  useCamelCaseFlagKeys?: boolean

  children: ReactElement

  /**
   * Contexts are used in targeting and rules to evaluate flags based on
   * different conditions.
   *
   * User and Account contexts are provided to LaunchDarkly by default, so
   * this property can be used to supply any additional custom contexts.
   *
   * A context must have the `kind` and `key` attributes, but
   * you can also supply any additional custom attributes.
   */
  contexts?: Record<
    string,
    {
      kind: string
      key: string
      name?: string
      anonymous?: boolean
      [key: string]:
        | boolean
        | number
        | string
        | []
        | Record<string, unknown>
        | undefined
    }
  >
}

const useLDContexts = () => {
  const { isLoggedIn } = useAuth()
  const user = useUser()

  return useMemo(() => {
    if (!isLoggedIn) {
      const userContext = {
        kind: 'user',
        key: 'unauthenticated-users',
        name: 'Unauthenticated Users',
        anonymous: true
      }
      const accountContext = {
        kind: 'account',
        key: 'unauthenticated-users',
        name: 'Unauthenticated Users',
        anonymous: true
      }
      return { userContext, accountContext }
    }

    const userContext = {
      kind: 'user',
      key: user.profile.email,
      email: user.profile.email,
      name: user.profile.name,
      avatar: user.profile.icon,
      organisationName: user.organisation.name,
      organisationId: user.organisation.id,
      roles: Array.from(user.roles),
      anonymous: false
    }
    const accountContext = {
      kind: 'account',
      key: user.organisation.id,
      name: user.organisation.name,
      id: user.organisation.id,
      subdomain: user.organisation.subdomain,
      region: user.organisation.region,
      anonymous: false
    }
    return { userContext, accountContext }
  }, [isLoggedIn, user])
}

/**
 * The FlagProvider connects to LaunchDarkly to provide feature flagging to your
 * application. It will automatically use the current auth0 user's details to
 * connect to LaunchDarkly, meaning that any applicable targeting will be
 * applied by default.
 *
 * If the user is not logged in, or the FlagProvider is rendered before the
 * AuthProvider has finished loading, LaunchDarkly will generate an ID for the
 * session on their side. Once the user has finished logging in, FlagProvider
 * will automatically update their details with LaunchDarkly, too, to ensure
 * that targeting is kept up-to-date.
 *
 * The FlagProvider must be used beneath the [[AuthProvider]].
 *
 * Example:
 * ```
  const App = ({children}) => {
   const enableExampleFlagByDefault = true

   return (
     <FlagProvider flags={{"example-flag": enableExampleFlagByDefault}}>
      {children}
    </FlagProvider>)
   }
 * ```
 *
 * @param props See [[FlagProviderProps]].
 * @category Components
 */
const FlagProvider: FunctionComponent<
  React.PropsWithChildren<FlagProviderProps>
> = ({
  launchDarklyClientId,
  flags = {},
  waitForFlags = false,
  useCamelCaseFlagKeys = true,
  offline,
  children,
  contexts = {}
}) => {
  const { environment, offline: isAuthProviderOffline } = useEnvironment()
  const defaultClientId = useMemo(
    () => getConfig(environment).launchDarkly.defaultClientId,
    [environment]
  )

  const { userContext, accountContext } = useLDContexts()
  const multiContext = {
    kind: 'multi',
    user: userContext,
    account: accountContext,
    ...contexts
  }

  const inOfflineMode =
    offline !== undefined ? !!offline : isAuthProviderOffline

  const providerProps: ProviderConfig = {
    clientSideID: launchDarklyClientId ?? defaultClientId,
    flags,
    reactOptions: {
      useCamelCaseFlagKeys
    },
    context: multiContext
  }

  if (inOfflineMode) {
    const defaultFlags = useCamelCaseFlagKeys ? camelCaseKeys(flags) : flags

    return (
      <FlagContext.Provider value={defaultFlags}>
        {children}
      </FlagContext.Provider>
    )
  }

  return waitForFlags ? (
    <AsyncFlagProvider providerProps={providerProps}>
      {children}
    </AsyncFlagProvider>
  ) : (
    <LDProvider {...providerProps}>{children}</LDProvider>
  )
}

export default FlagProvider
