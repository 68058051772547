import { FC, PropsWithChildren, ReactElement, useEffect } from 'react'
import useUser from '../../effects/useUser'
import getAppURL from '../../utils/getAppURL'
import locationRedirect from '../../utils/locationRedirect'
import { Button, Typography } from '@matillion/component-library'
import useEnvironment from '../../effects/useEnvironment'
import useAuth from '../../effects/useAuth'

const ADMIN_MESSAGE =
  "To access this functionality, you'll need to select a region for your account. You can do this over on Matillion Hub, it'll just take a few moments."
const NON_ADMIN_MESSAGE =
  "To access this functionality, you'll need to have a region set on your account. You can ask the account administrator at your company to do this."

export interface RegionGuardProps {
  /**
   * If true, the user will be guided back to the Hub if the account doesn't have a region set.
   */
  strictAccountRegion?: boolean
  /**
   * If true, the user will be redirected to the correct regional domain if they arrive at a non-regional one.
   */
  strictRegionalDomain?: boolean
  /**
   * Fallback content to be used if `strictAccountRegion` check fails (account has no region).
   * If omitted, standard messaging around returning to Hub is shown.
   */
  fallback?: ReactElement
}

export const RegionGuard: FC<PropsWithChildren<RegionGuardProps>> = ({
  strictAccountRegion = false,
  strictRegionalDomain = false,
  fallback,
  children
}) => {
  const { hubUrl } = useEnvironment()
  const { isLoggedIn } = useAuth()
  const { organisation, roles } = useUser()
  const isAdmin = roles.has('hub:admin')
  useEffect(() => {
    if (organisation.region) {
      const url = getAppURL()
      if (url.region && url.region !== organisation.region) {
        locationRedirect(
          url.origin.replace(
            `${url.region}.matillion.com`,
            organisation.region + '.matillion.com'
          )
        )
      } else if (!url.region && strictRegionalDomain) {
        locationRedirect(
          url.origin.replace(
            'matillion.com',
            organisation.region + '.matillion.com'
          )
        )
      }
    }
  }, [organisation.region])
  useEffect(() => {
    if (isLoggedIn && !organisation.region) {
      console.warn(
        'hub-client: account without region set: %s',
        organisation.id
      )
    }
  }, [isLoggedIn, organisation.region])
  if (isLoggedIn && strictAccountRegion && !organisation.region) {
    return (
      fallback ?? (
        <div
          style={{
            maxWidth: '450px',
            margin: '32px auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            textAlign: 'center'
          }}
        >
          <Typography>{isAdmin ? ADMIN_MESSAGE : NON_ADMIN_MESSAGE}</Typography>
          <div>
            <a href={hubUrl}>
              <Button
                as="span"
                alt="primary"
                fontWeight="bold"
                text={
                  isAdmin ? 'Update your account' : 'Select another account'
                }
              />
            </a>
          </div>
        </div>
      )
    )
  }
  return <>{children}</>
}
